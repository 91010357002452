<template>
  <div class="bg-body-light">
    <div class="content content-full">
      <slot>
        <div
          v-if="title"
          class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2"
        >
          <div class="flex-grow-1">
            <h1 v-if="title" class="h3 fw-bold mb-2">
              {{ title }}
            </h1>
            <h2
              v-if="subtitle"
              class="fs-base lh-base fw-medium text-muted mb-0"
            >
              {{ subtitle }}
            </h2>
          </div>
          <div
            v-if="breadCrumbs?.length > 0"
            class="flex-shrink-0 mt-3 mt-sm-0 ms-sm-3"
          >
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li
                  v-for="crumb in breadCrumbs"
                  :key="crumb"
                  class="breadcrumb-item"
                  :aria-current="currentPage"
                >
                  {{ crumb }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </slot>
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
// Component properties
const props = defineProps({
  title: {
    type: String,
    description: "The title of page heading section",
  },
  subtitle: {
    type: String,
    description: "The subtitle of page heading section",
  },
  breadCrumbs: {
    type: Array,
    description: "Array of bread crumbs",
    default() {
      return [];
    },
  },
});

const currentPage = computed(() => {
  if (props.breadCrumbs?.length <= 0) return;

  return props.breadCrumbs[props.breadCrumbs.length - 1];
});
</script>
