import { createApp } from 'vue';
import { createNotivue } from 'notivue';
import { createPinia } from 'pinia';
import globalVars from '@/globalVars';

import App from './App.vue';

// You can use the following starter router instead of the default one as a clean starting point
// import router from "./router/starter";
import router from './router';

// Template components
import BaseBlock from '@/components/BaseBlock.vue';
import BaseBackground from '@/components/BaseBackground.vue';
import BasePageHeading from '@/components/BasePageHeading.vue';

// Template directives
import clickRipple from '@/directives/clickRipple';
import { vMaska } from "maska";

// Font Awesome
import '@/lib/font-awesome-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-free/css/all.css';

// Bootstrap framework
import * as bootstrap from 'bootstrap';

// Tailwind CSS
import '@/assets/scss/tailwind.scss';

// Notivue
import 'notivue/notifications.css'; // Only needed if using built-in notifications
import 'notivue/animations.css'; // Only needed if using built-in animations
// import 'notivue/notification-progress.css'

window.bootstrap = bootstrap;

// Craft new application
const app = createApp(App);

// Register global components
app.component('BaseBlock', BaseBlock);
app.component('BaseBackground', BaseBackground);
app.component('BasePageHeading', BasePageHeading);
app.component('FontAwesomeIcon', FontAwesomeIcon); 
app.component('FontAwesomeLayers', FontAwesomeLayers);

// Register global directives
app.directive('click-ripple', clickRipple);
app.directive('maska', vMaska);

// Use Notivue, Pinia and Vue Router
app.use(createNotivue(
  {
    avoidDuplicates: true,
    enter: 'fade', 
    leave: 'fade',
    notifications: {
      global: {
        duration: 3500,
      },
    },
    position: 'top-right', 
    verticalOffset: '0px',
  }
));
app.use(createPinia());
app.use(router);

// global varriables
app.use(globalVars);

// ..and finally mount it!
app.mount('#app');
