import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { storageWrapper } from '@/helpers/localStorageWrapper';
import { useUserStore } from '@/stores/user';
const userKey = 'user';
const sessionKey = 'session';
const sectorKey = 'sector';
const siteKey = 'site';
const appsKey = 'apps';
const navigationKey = 'navigation';

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    returnUrl: null,
    user: null,
    session: null,
    site: null,
    apps: {},
    navigation: [],
  }),
  actions: {
    async login(email, password) {
      return fetchWrapper.post('/login', { email, password }).then((data) => {
        if (data?.token && data.user) {
          // Save object attributes email, first name, last name, and full name
          var user = {
            token: data.token,
            email: data.user.email,
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            id: data.user.id,
            navigation: data.user.navigation,
						isOrganizationAdmin: data.user.isOrganizationAdmin, 
						isPlatformAdmin: data.user.isPlatformAdmin, 
						isRegionAdmin: data.user.isRegionAdmin, 
						isSiteAdmin: data.user.isSiteAdmin, 
						isSystemAdmin: data.user.isSystemAdmin,        
					};
					console.log(user);
          // Store user details and jwt in local storage to keep user logged in between page refreshes
          this.setUser(user);
          this.setNavigationSession(user.navigation);
          return true;
        } else {
					return false;
				}
      });
    },
    logout() {
      this.user = {};
      this.session = {};
      this.apps = {};
      (this.navigation = []), storageWrapper.clear();
      const userStore = useUserStore();
      userStore.clearUserData();
    },
    hasAppAccess(appName, type, sub, appAccessName) {
      if (!appName || !type || !sub) 
				return true;

      let apps = this.getAppsSession();
      let access = false;
      let name = appAccessName ? appAccessName.split("-") : appName.split("-");
      name.filter;
      name = name.join(" ");
      if (apps && apps[type] && apps[type][sub]?.length > 0) {
        apps[type][sub].forEach((app) => {
          if (app.name.toLowerCase() == name.toLowerCase()) {
            access = true;
            return;
          }
        });
      }

      return access;
    },
    // --- Set Session Variables
    setSession(key, value) {
      let session = this.session;
      if (!session) 
				session = {};

      session[key] = value;
      this.session = session;
      storageWrapper.setItem(sessionKey, JSON.stringify(session));
    },
    setUser(user) {
      this.user = user;
      this.setSession(userKey, user);
    },
    setSectorSession(sectorId) {
      this.setSession(sectorKey, sectorId);
    },
    setSiteSession(siteId) {
      this.setSession(siteKey, siteId);
    },
    setAppsSession(apps) {
      this.apps = apps;
      this.setSession(appsKey, apps);
    },
    setNavigationSession(navigation) {
      this.navigation = navigation;
      this.setSession(navigationKey, navigation);
    },
    // --- Get Session Varriables ---
    getSession(key) {
      if (!key) 
				return null;

      let session = JSON.parse(storageWrapper.getItem(sessionKey));
      this.session = session;
      return session[key];
    },
    getUser() {
      let user = this.getSession(userKey);
      if (!user || !user.token) 
				user = {};

      this.user = user;
      return user;
    },
    getSectorSession() {
      return this.getSession(sectorKey);
    },
    getSiteSession() {
      return this.getSession(siteKey);
    },
    getNavigationSession() {
      return this.getSession(navigationKey);
    },
    getAppsSession() {
      if (this.apps.length > 0) 
				return this.apps;

      return this.getSession(appsKey);
    },
    async forgotPassword(email) {
      return fetchWrapper.post("/password-resets", { email }).then((data) => {
        return true;
      });
    },
    async resetPassword(password, publicKey) {
      return fetchWrapper
        .post(`/password-resets/${publicKey}`, { password })
    },
    async checkPublicKeyValidity(publicKey) {
      return fetchWrapper
        .get(`/password-resets/validate/${publicKey}`)
        .then ((data) => {
						return { data };
        });
    },
  },
});
