<template>
  <Notivue v-slot="item">
    <Notification
      :item="item"
      :theme="pastelTheme"
    />
  </Notivue>

  <BaseLayout>
    <!-- Side Overlay Content -->
    <!-- Using the available v-slot, we can override the default Side Overlay content from layouts/partials/SideOvelay.vue -->
    <template #side-overlay-content>
      <div class="content-side">
        <p>Side Overlay content..</p>
      </div>
    </template>
    <!-- END Side Overlay Content -->

    <!-- Sidebar Content -->
    <!-- Using the available v-slot, we can override the default Sidebar content from layouts/partials/Sidebar.vue -->
    <template #sidebar-content>
      <div class="content-side">
        <BaseNavigation :nodes="dashboardNode" />
        <BaseNavigation
          v-if="userStore.globalNodes && userStore.globalNodes.length > 0"
          :nodes="userStore.globalNodes"
        />
        <BaseNavigation
          v-if="userStore.activeCampus"
          :nodes="userStore.campusNodes"
        />
      </div>
    </template>
    <!-- END Sidebar Content -->

    <!-- Header Content Left -->
    <!-- Using the available v-slot, we can override the default Header content from layouts/partials/Header.vue -->
    <template #header-content-left> </template>
    <!-- END Header Content Left -->

    <!-- Header Content Right -->
    <!-- Using the available v-slot, we can override the default Header content from layouts/partials/Header.vue -->
    <template #header-content-right>
      <!-- TODO: Componentize this -->
      <!-- Sector Selector -->
      <div
        v-if="userStore.sectorOptions.length > 1 && !state.selectedSectorName"
        :options="userStore.sectorOptions"
        class="dropdown d-inline-block ms-1"
      >
        <button
          id="sector-selector"
          type="button"
          class="btn btn-sm btn-alt-secondary"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{
            state.selectedSectorName?.length > 0
              ? state.selectedSectorName
              : "Select a sector"
          }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-start fs-sm border-0"
          aria-labelledby="sector-selector"
        >
          <button
            v-for="sector in userStore.sectorOptions"
            :key="sector.id"
            type="button"
            class="dropdown-item d-flex align-items-center justify-content-between fw-medium"
            @click="sectorHandler(sector.value, sector.name)"
            @keypress="sectorHandler(sector.value, sector.name)"
          >
            <span>{{ sector.name }}</span>
          </button>
        </div>
      </div>

      <!-- TODO: Componentize this -->
      <!-- Site Selector -->
      <div
        v-if="
          userStore.siteOptions.length > 0 &&
          state.selectedSectorName.length > 0
        "
        :options="userStore.siteOptions"
        class="dropdown d-inline-block ms-1"
      >
        <button
          id="site-selector"
          type="button"
          class="btn btn-sm btn-alt-secondary"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{
            state.selectedSiteName?.length > 0
              ? state.selectedSiteName
              : "Select a site"
          }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-start fs-sm smini-hide border-0 overflow-auto"
          aria-labelledby="site-selector"
          style="max-height: 450px;"
        >
          <button
            v-for="site in userStore.siteOptions"
            :key="site.id"
            type="button"
            class="dropdown-item d-flex align-items-center justify-content-between fw-medium"
            @click="() => siteHandler(site.value, site.name)"
            @keypress="() => siteHandler(site.value, site.name)"
          >
            <span>{{ site.name }}</span>
          </button>
        </div>
      </div>

      <!-- User Dropdown -->
      <div class="dropdown d-inline-block ms-2">
        <button
          id="page-header-user-dropdown"
          type="button"
          class="btn btn-sm btn-alt-secondary d-flex align-items-center"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            class="rounded-circle"
            src="/assets/media/avatars/avatar10.jpg"
            alt="Header Avatar"
            style="width: 21px"
          />
          <span class="d-none d-sm-inline-block ms-2">
            {{ Session.user.firstName }}
          </span>
          <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-md dropdown-menu-start p-0 border-0"
          aria-labelledby="page-header-user-dropdown"
        >
          <div class="p-3 text-center bg-body-light border-bottom rounded-top">
            <img
              class="img-avatar img-avatar48 img-avatar-thumb"
              src="/assets/media/avatars/avatar10.jpg"
              alt="Header Avatar"
            />
            <p class="mt-2 mb-0 fw-medium">
              {{ Session.user.firstName }} {{ Session.user.lastName }}
            </p>
            <p class="mb-0 text-muted fs-sm fw-medium">Web Developer</p>
          </div>
          <div class="p-2">
            <a
              class="dropdown-item d-flex align-items-center justify-content-between"
              href="javascript:void(0)"
            >
              <span class="fs-sm fw-medium">Inbox</span>
              <span class="badge rounded-pill bg-primary ms-2">3</span>
            </a>
            <a
              class="dropdown-item d-flex align-items-center justify-content-between"
              href="javascript:void(0)"
            >
              <span class="fs-sm fw-medium">Settings</span>
            </a>
          </div>
          <div
            role="separator"
            class="dropdown-divider m-0"
          ></div>
          <div class="p-2">
            <!-- <RouterLink
          :to="{ name: 'auth-lock' }"
          class="dropdown-item d-flex align-items-center justify-content-between"
        >
          <span class="fs-sm fw-medium">Lock Account</span>
        </RouterLink> -->
            <RouterLink
              :to="{ path: '/login', params: 'logout' }"
              class="dropdown-item d-flex align-items-center justify-content-between"
            >
              <span class="fs-sm fw-medium">Log Out</span>
            </RouterLink>
          </div>
        </div>
      </div>
      <!-- END User Dropdown -->
      <!-- Toggle Side Overlay -->
      <button
        type="button"
        class="btn btn-sm btn-alt-secondary ms-2"
        @click="store.sideOverlay({ mode: 'toggle' })"
      >
        <FontAwesomeIcon :icon="['far', 'list-ul']" />
      </button>
      <!-- END Toggle Side Overlay -->
    </template>
    <!-- END Header Content Right -->

    <!-- Footer Content Left -->
    <!-- Using the available v-slot, we can override the default Footer content from layouts/partials/Footer.vue -->
    <template #footer-content-left>
      &copy; {{ store.app.copyright }}
      <strong>RoaringSky</strong>
    </template>
    <!-- END Footer Content Left -->
  </BaseLayout>
</template>

<script setup>
import { useTemplateStore } from "@/stores/template";
import { useAuthStore } from "@/stores/auth";
import { useSectorStore } from "@/stores/sectors";
import { useUserStore } from "@/stores/user";
import { reactive, onMounted } from "vue";
import { Notivue, Notification, pastelTheme } from "notivue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import BaseNavigation from "@/components/BaseNavigation.vue";

// Main store
const store = useTemplateStore();
const Session = useAuthStore();
const userStore = useUserStore();

// Set default elements for this layout
store.setLayout({
  header: true,
  sidebar: true,
  sideOverlay: true,
  footer: true,
});

// Set various template options for this layout variation
store.headerStyle({ mode: "light" });
store.mainContent({ mode: "narrow" });
const state = reactive({
  selectedSectorName: "",
  selectedSiteName: "",
});

const dashboardNode = [
  {
    name: "Dashboard",
    to: "dashboard",
    icon: "gauge-max",
    icon_style: "far",
  },
];

onMounted(async () => {
  let { sectors, sectorOptions } = useUserStore();
  if (sectors.length <= 0) {
    const sectorStore = useSectorStore();
    sectors = await sectorStore.getUserSectors();
  }

  if (sectorOptions.length <= 0) {
    let [options, selectedSector] = buildSelectOptions(
      sectors,
      Session.getSectorSession()
    );

    userStore.sectorOptions = options;

    if (selectedSector !== "undefiend")
      sectorHandler(selectedSector.id, selectedSector.name);
  }
});

async function sectorHandler(sectorId, sectorName) {
  userStore.activeCampus = false;
  if (!sectorId) return;

  let selectedSectorId = Session.getSectorSession();
  if (sectorId === selectedSectorId && userStore.siteOptions.length > 0) return;
  state.selectedSectorName = sectorName;
  Session.setSectorSession(sectorId);
  const sectorStore = useSectorStore();
  let [sites, assignedApps] = await Promise.all([
    sectorStore.getSectorSites(sectorId),
    sectorStore.getAssignedAppsBySector(sectorId),
  ]);

  let [options, selectedSite] = buildSelectOptions(
    sites,
    Session.getSiteSession()
  );
  var globalNodes = [];
  var campusNodes = [];
  if (assignedApps) [globalNodes, campusNodes] = buildNodes(assignedApps);

  userStore.siteOptions = options;
  userStore.globalNodes = globalNodes;
  userStore.campusNodes = campusNodes;
  if (selectedSite.length > 0) siteHandler(selectedSite.id, selectedSite.name);
}

async function siteHandler(siteId, siteName) {
  state.selectedSiteName = siteName;
  Session.setSiteSession(siteId);
  userStore.activeCampus = true;
}

// TODO: This should be refactored with Formatter.
// Formatter wasn't built out when this was made.
function buildSelectOptions(data, selectedId, originalName = null) {
  let options = [];
  if (originalName != null) options.push({ value: null, name: originalName });

  let selectedOption = {};
  if (data != null && data.length > 0) {
    data.forEach((item) => {
      let selected = data.length == 1 || item.id == selectedId;
      if (selected) selectedOption = { id: item.id, name: item.name };

      options.push({
        value: item.id,
        name: item.name,
        select: selected,
      });
    });
  }
  return [options, selectedOption];
}

function buildNodes(assignedApps) {
  // Set the user's navigation
  const navigation = Session.getNavigationSession();
  // Set the global and campus navigation arrays
  const globalAppGroups = navigation.find(
    (nav) => nav.name === "Global"
  ).app_groups;
  const campusAppGroups = navigation.find(
    (nav) => nav.name === "Campus"
  ).app_groups;
  // Set globalNodes and campusNodes for the navigation UI
  const globalNodes = globalAppGroups.map((group) => {
    return {
      name: group.name,
      icon: group.icon,
      icon_style: group.icon_style,
      sub: group.apps,
      heading: group.heading,
    };
  });
  const campusNodes = campusAppGroups.map((group) => {
    return {
      name: group.name,
      icon: group.icon,
      icon_style: group.icon_style,
      sub: group.apps,
      heading: group.heading,
    };
  });
  return [globalNodes, campusNodes];
}
</script>

<style module lang="scss">
.navContainer {
  display: flex;
}

.navGroupContainer {
  padding: 20px;
}
:root {
  --nv-root-top: 4.6875rem;
}
</style>
